// ==============================================

//        SLIDER SLICK CALCUL

// ==============================================


$(document).ready(function(){

  //        Init buttons
  // ==============================================
  var listing   = document.querySelector('.project.--list'),
      title     = listing.querySelector('h3'),
      back      = listing.querySelectorAll('.btn__hide'),
      give      = listing.querySelectorAll('.btn__give'),
      thumbs    = document.querySelectorAll('.btn__show'),
      nav_con   = document.querySelectorAll('.content__nav'),
      projects  = document.querySelectorAll('.project-slide');

  function show_project(current) {
    listing.classList.add('--selected');

    projects.forEach(el=>{
      if (el.getAttribute('id') == current) {
        el.classList.add('--show');
      } else {
        el.classList.remove('--show');
      }
    });
    thumbs.forEach(el=>{
      if (el.getAttribute('project-id') == current) {
        el.parentNode.parentNode.classList.add('--hide');
        el.parentNode.parentNode.classList.remove('--in-row');
      } else {
        el.parentNode.parentNode.classList.remove('--hide');
        el.parentNode.parentNode.classList.add('--in-row');
      }
    });
    //
    title.classList.add('--hide');
    //
    var ny = document.querySelector('#the-projects').getBoundingClientRect().top + window.scrollY - 30;
    window.scroll({ top: ny, left: 0, behavior: 'smooth' });
  }
  //set buttons to open projects blocks
  document.querySelectorAll('.btn__show').forEach(el=> {
    el.addEventListener('click', e=>{
      show_project( e.target.getAttribute('project-id') );
      return true;
    });
  });

  //set buttons to close all projects opened
  back.forEach(el => {
    el.addEventListener('click', e=>{
      projects.forEach(el=>{
        el.classList.remove('--show');
      });
      thumbs.forEach(el=>{
        el.parentNode.classList.remove('--hide', '--in-row');
      });
      listing.classList.remove('--selected');
      title.classList.remove('--hide');
      return true;
    });
  });

  //set internal nav for content
  nav_con.forEach(el => {
    el.addEventListener('click', e=>{
      if (e.target.classList.contains('.--active')) return;
      //
      var current = e.target.getAttribute('content-id'),
          con = e.target.parentNode.parentNode.parentNode.querySelectorAll('.content, .content__nav li' );
      con.forEach(c => {
        if ( current == c.getAttribute('content-id')) {
          c.classList.add('--active');
        } else {
          c.classList.remove('--active');          
        }
      });
    });
  });

  //        If hash value -> set initialSlide
  // ==============================================

  var pattern = 'projet';
  var hash    = location.hash.substr(1);

  if (hash) {
    if (hash.startsWith(pattern)) {     
      // auto scroll to nav anchor
      setTimeout(function(){
        var id      = Number(hash.charAt(hash.length - 1) ) ;
        console.log('>', id);
        //
        show_project ( id );
      }, 800);
    } else if (hash == 'plateforme') {
      var ny = document.querySelector('#plateforme').getBoundingClientRect().top + window.scrollY - 80;
      window.scroll({ top: ny, left: 0, behavior: 'smooth' });
    }
  }


  // var sliderSettings = {
  //   dots         : false,
  //   arrows       : true,
  //   infinite     : true,
  //   speed        : 700,
  //   cssEase      : 'ease-in-out',
  //   appendArrows : '.slider-controls',
  //   prevArrow    : '.slick-prev',
  //   nextArrow    : '.slick-next',
  //   initialSlide  : startSlide-1,
  //   adaptiveHeight : true,

  //   responsive: [
  //     {
  //       breakpoint: 640,
  //       settings  : {
  //         arrows    : false,
  //         fade      : true,
  //         speed     : 100,
  //         cssEase   : 'linear',
  //       }
  //     },
  //   ],
  // };


  // // init -> set active link in nav
  // $(slider).on('init', function (event, slick) {
  //   $('.nav-project__link[data-slide='+(startSlide)+']').addClass('is-active');
  //   $('.nav-project-mobile__link[data-slide='+(startSlide)+']').addClass('is-active');

  //   setBtnLabel(startSlide);
  // });


  // if ($(slide).length  > 1) {
  //   $(slider).slick(sliderSettings);
  // }


  // // Slider navigation
  // $('.nav-project__link, .nav-project-mobile__link').click(function(e) {
  //   e.preventDefault();
  //   var slideId = $(this).data('slide');
  //   $(slider).slick('slickGoTo', slideId - 1);
  // });

  
  // $('.nav-project__select select').change(function(e) {
  //   var slideId = $(this).val();
  //   $(slider).slick('slickGoTo', slideId - 1);
  // });

  

  // // Activate / desactivate nav link when slider change
  // $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
  //   $('.nav-project__link[data-slide='+(currentSlide+1)+']').removeClass('is-active');
  //   $('.nav-project__link[data-slide='+(nextSlide+1)+']').addClass('is-active');
  //   $('#slick-prev').addClass('is-sliding');
  //   $('#slick-next').addClass('is-sliding');

  //   // pause youtube player when slide
  //   var currentSlideEl = document.getElementById('project-slide'+(currentSlide+1));
  //   var player = currentSlideEl.querySelector('iframe');
    
  //   // post our command to the iframe.
  //   if (player != undefined) {
  //       var command = {
  //           "event": "command",
  //           "func": "pauseVideo"
  //       };
  //       player.contentWindow.postMessage(JSON.stringify(command), "*");
  //   }
  // });


  // $(slider).on('afterChange', function(event, slick, currentSlide) {
  //   $('.nav-project-mobile__link.is-active').removeClass('is-active');
  //   $('.nav-project-mobile__link[data-slide='+(currentSlide+1)+']').addClass('is-active');
  //   $('#slick-prev').removeClass('is-sliding');
  //   $('#slick-next').removeClass('is-sliding');
  //   setBtnLabel(currentSlide+1);
  // });


  // // Nav -> scroll to slider top;
  // $('#slick-prev, #slick-next').click(function(e) {
  //   e.preventDefault();
  //   scrollToSliderTop(600);
  // });
  // $('.nav-project__link').click(function(e) {
  //   scrollToSliderTop(600);
  // });
  // $('.nav-project-mobile__link').click(function(e) {
  //   var ny = document.querySelector('#project-slider').getBoundingClientRect().top + window.scrollY - 30;
  //   window.scroll({ top: ny, left: 0, behavior: 'smooth' });
  // });


  // Scroll to anchor #fiscal
  function scrollToAnchor(hash, offset) {
    var offset  = (typeof offset !== 'undefined') ? offset : 0;
    // offset  += (window.innerWidth > 750) ? 0 : 15;
    var target  = document.getElementById(hash);
    // var targetY = target.offsetTop + offset;
    var targetY = target.getBoundingClientRect().top + window.pageYOffset + offset;

    // scroll to section
    $('body,html').animate({ scrollTop : targetY }, 800, 'easeInOutCubic');
  }


  // Btn next / prev navigation -> scroll to slider top
  function scrollToSliderTop(speed) {
    var speed   = (typeof speed !== 'undefined') ? speed : 50;
    var offset  = -90;
    var target  = document.getElementById('nav-project');
      if (target.getBoundingClientRect().top==0) target = document.querySelector('section.project'); 
    // var targetY = target.offsetTop + offset;
    var targetY = target.getBoundingClientRect().top + window.pageYOffset + offset;

    // scroll to section
    $('body,html').animate({ scrollTop : targetY }, speed, 'easeInOutCubic');

    console.log('scrollToSliderTop>', targetY)
  }


  // change label on button next / prev
  function setBtnLabel(currentId) {
    var prevId = (currentId > 1 ) ? currentId-1 : 3;
    var nextId = (currentId < 3 ) ? currentId+1 : 1;
    var labelPrev = $('.nav-project__link[data-slide='+prevId+']').attr('data-label');
    var labelNext = $('.nav-project__link[data-slide='+nextId+']').attr('data-label');
    $('#slick-prev span').html(labelPrev);
    $('#slick-next span').html(labelNext);
    // console.log(prevId, currentId, nextId, '/', labelPrev, labelNext);
  }


});
